// extracted by mini-css-extract-plugin
export var advisorsMember = "Advisors-module--advisorsMember--002cf";
export var advisorsWrap = "Advisors-module--advisorsWrap--44568";
export var bottomRow = "Advisors-module--bottomRow--9f920";
export var divider = "Advisors-module--divider--d261d";
export var heading = "Advisors-module--heading--b8b11";
export var headingWrap = "Advisors-module--headingWrap--64b2d";
export var imgWrap = "Advisors-module--imgWrap--83a08";
export var loadmoreBtn = "Advisors-module--loadmoreBtn--e68cc";
export var name = "Advisors-module--name--9ccd7";
export var position = "Advisors-module--position--f3112";
export var row = "Advisors-module--row--9aee9";
export var subHeading = "Advisors-module--subHeading--f1641";