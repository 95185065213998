// extracted by mini-css-extract-plugin
export var lottieAnimation = "Roadmap-module--lottieAnimation--6ea1e";
export var primarySwiper = "Roadmap-module--primarySwiper--b06c3";
export var rightSide = "Roadmap-module--rightSide--ffd76";
export var roadmapWrap = "Roadmap-module--roadmapWrap--4ee88";
export var slideActive = "Roadmap-module--slideActive--09456";
export var slideTitle = "Roadmap-module--slideTitle--80a38";
export var slideWrap = "Roadmap-module--slideWrap--da49a";
export var sliderWrap = "Roadmap-module--sliderWrap--b955f";
export var sliderWrapHeading = "Roadmap-module--sliderWrapHeading--7a7c8";
export var sliderWrapSubHeading = "Roadmap-module--sliderWrapSubHeading--72d02";
export var sliderWrapper = "Roadmap-module--sliderWrapper--a05e8";
export var time = "Roadmap-module--time--8e779";
export var topRow = "Roadmap-module--topRow--ce516";