// extracted by mini-css-extract-plugin
export var actionButton1 = "PublicRPC-module--actionButton1--1039d";
export var actionButton2 = "PublicRPC-module--actionButton2--36916";
export var buttonsContainer = "PublicRPC-module--buttonsContainer--d1122";
export var dropdown = "PublicRPC-module--dropdown--d3ac7";
export var publicRpcContainer = "PublicRPC-module--publicRpcContainer--632dd";
export var rpcBottomRow = "PublicRPC-module--rpcBottomRow--23aa1";
export var rpcFormBlock = "PublicRPC-module--rpcFormBlock--49fb3";
export var rpcHeadingBlock = "PublicRPC-module--rpcHeadingBlock--66b37";
export var rpcUrl = "PublicRPC-module--rpcUrl--72022";
export var selectorLabel = "PublicRPC-module--selectorLabel--d9e64";
export var selectorRow = "PublicRPC-module--selectorRow--af76b";
export var topHeading = "PublicRPC-module--topHeading--9e0bf";
export var topSubHeading = "PublicRPC-module--topSubHeading--b4e75";