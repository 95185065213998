import React from "react";
import Container from "../../components/Container/Container";
import * as Styles from "./Products.module.scss";
import Video1 from "../../videos/Ipad1.mp4";
import Video2 from "../../videos/Ipad2.mp4";
import Video3 from "../../videos/Ipad3.mp4";

const Products = () => {
  return (
    <section className={Styles.productsWrap} id="showcase">
      <Container>
        <div className={Styles.productsContent}>
          <div className={Styles.productFullWidth}>
            <div className={Styles.fullImgWrap}>
              <video autoPlay muted loop playsInline>
                <source src={Video1} type="video/mp4" />
              </video>
            </div>
            <h2>Generating API endpoint</h2>
            <p>
              Generate a secure connection to blockchain for your wallet or app
            </p>
          </div>

          <div className={Styles.row}>
            <div>
              <div className={Styles.smallImgWrap}>
                <video autoPlay muted loop playsInline>
                  <source src={Video2} type="video/mp4" />
                </video>
              </div>
              <h2>Managing API endpoints</h2>
              <p>
                You have full control over all secure connections you have
                generated
              </p>
            </div>

            <div>
              <div className={Styles.smallerImgWrap}>
                <video autoPlay muted loop playsInline>
                  <source src={Video3} type="video/mp4" />
                </video>
              </div>
              <h2>Front-running protection</h2>
              <p>
                Your DEX transactions will not be seen by hungry sandwich bots
                in the public mempool
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Products;
