import React from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "../../components/Container/Container";
import SubscribeForm from "../../components/SubscribeForm/SubscribeForm";
import {
  SvgNumbersVolumes,
  SvgNumbersUsers,
  SvgNumbersServer,
  SvgNumbersNetworks,
  SvgNumbersRpc,
  SvgNumbersClients
} from "../../components/SVG";
import * as Styles from "./Community.module.scss";

const baseUsersCount = 6374592;
const baseUsersTimestamp = '2024-09-01T08:00:00.000Z';
const usersPerHour = 150;

function armLiveUserUpdate() {
  setTimeout(function () {
    const userCountStrWithCommas = document.getElementById(usersElementId).innerText;
    const usersCountStr = userCountStrWithCommas.replace(/,/g, '');

    let usersCount = parseInt(usersCountStr);
    document.getElementById(usersElementId).innerText = (usersCount + 1).toLocaleString();
    armLiveUserUpdate();
  }, 3600 * 1000 / usersPerHour );

}

//Start live updates after 500 miliseconds
setTimeout( armLiveUserUpdate, 500);
let usersElementId;

const startTimestamp = new Date(baseUsersTimestamp).valueOf();
const currentTimestamp = Date.now().valueOf();
const usersSinceDeployed = Math.floor(((currentTimestamp - startTimestamp) / 1000) / (3600/usersPerHour));
const currentBaseUsers = baseUsersCount + usersSinceDeployed;

const communityList = [
  {
    icon: <SvgNumbersNetworks />,
    number: "70+",
    label: "Supported networks",
  },
  {
    icon: <SvgNumbersUsers />,
    number: currentBaseUsers.toLocaleString(),
    label: "Monthly active users",
    liveUpdate: true
  },
  {
    icon: <SvgNumbersClients />,
    number: "35+",
    label: "B2B Clients",
  },
  {
    icon: <SvgNumbersServer />,
    number: "100%",
    label: "Uptime",
  },
  {
    icon: <SvgNumbersRpc />,
    number: "1B+",
    label: "RPC Calls/Day",
  },
  {
    icon: <SvgNumbersVolumes />,
    number: "$3B+",
    label: "Monthly processed volume",
  }, 
];

const Community = () => {
  return (
    <section id="community">
      <Container smaller>
        <div className={Styles.headingWrap}>
          <div className={Styles.upperHeading}>OUR COMMUNITY</div>
          <h2>OMNIA in numbers</h2>
        </div>
        <div className={Styles.row}>
          {communityList.map((item) => {
            const { icon, number, label } = item;
            let id = uuidv4();
            if (item.liveUpdate) {
              usersElementId = id;
            }
            return (
              <div className={Styles.communityItemWrap} key={uuidv4()}>
                <div className={Styles.icon}>{icon}</div>
                <div className={Styles.number} id={id}>{number}</div>
                <div className={Styles.label}>{label}</div>
              </div>
            );
          })}
        </div>
      </Container>
      <SubscribeForm />
    </section>
  );
};

export default Community;
