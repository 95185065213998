import React, { useState } from "react";
import Container from "../../components/Container/Container";
import * as Styles from "./PublicRPC.module.scss";
import { EVMChainsConfig } from "../../constants";
import Button from "../../components/Button/Button";
import Select, { components } from "react-select";

import ethSvg from "../../../static/svg/eth.svg";
import bscSvg from "../../../static/svg/bsc.svg";
import maticSvg from "../../../static/svg/matic.svg";
import arbitrumSvg from "../../../static/svg/arbitrum.svg";
import avaxSvg from "../../../static/svg/avax.svg";
import auroraSvg from "../../../static/svg/aurora.svg";

const PublicRPC = () => {
  const [selectedRpc, setSelectedRpc] = useState("Ethereum");
  const extentionAlertMessage1 =
    "For this action to work, please disable all other wallet extensions that are not MetaMask.";
  const extentionAlertMessage2 =
    "For this action to work, please install the MetaMask browser extension!";

  const options = [
    { value: "Ethereum", label: "Ethereum", icon: ethSvg },
    {
      value: "Binance Smart Chain",
      label: "Binance Smart Chain",
      icon: bscSvg,
    },
    { value: "Polygon", label: "Polygon", icon: maticSvg },
    { value: "Arbitrum One", label: "Arbitrum One", icon: arbitrumSvg },
    { value: "Avalanche", label: "Avalanche", icon: avaxSvg },
    { value: "Aurora", label: "Aurora", icon: auroraSvg },
  ];

  const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: "center",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "center",
    }),
  };

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className={Styles.selectorRow}>
        <img src={props.data.icon} alt={props.data.label} />
        <div className={Styles.selectorLabel}>{props.data.label}</div>
      </div>
    </Option>
  );

  const handleCopyValue = () => {
    const valueToCopy = EVMChainsConfig[selectedRpc].rpcUrls[0];
    navigator.clipboard.writeText(valueToCopy).then(() => {
      alert("Endpoint URL copied to clipboard!");
    });
  };

  const handleAddToMetaMask = async () => {
    try {
      const chainParams = EVMChainsConfig[selectedRpc];
      if (window.ethereum !== "undefined") {
        if (window.ethereum.providers?.length) {
          alert(extentionAlertMessage1);
        } else {
          if (window.ethereum && window.ethereum.isMetaMask) {
            if (window.ethereum.isTrust) {
              alert(extentionAlertMessage1);
            } else {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [chainParams],
              });
            }
          } else {
            alert(extentionAlertMessage1);
          }
        }
      } else {
        alert(extentionAlertMessage2);
      }
    } catch (e) { }
  };

  return (
    <section id="public-rpc">
      <>
        <>
          <Container>
            <div className={Styles.rpcHeadingBlock}>
              <div className={Styles.topSubHeading}>Public RPC</div>
              <div className={Styles.topHeading}>
                Fast, Free and Secure Public RPC endpoints with Enhanced Privacy and High Availability guarantees
              </div>
            </div>
          </Container>
        </>
        <div className={Styles.rpcBottomRow}>
          <Container>
            <div className={Styles.rpcFormBlock}>
              <div className={Styles.publicRpcContainer}>
                {/* Dropdown */}
                <Select
                  options={options}
                  onChange={(event) => setSelectedRpc(event.value)}
                  components={{ Option: IconOption }}
                  className={Styles.dropdown}
                  placeholder="Choose Chain..."
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={options[0]}
                />
                {/* Display Field */}
                <div>
                  <p className={Styles.rpcUrl}>
                    {selectedRpc ? EVMChainsConfig[selectedRpc].rpcUrls[0] : ""}
                  </p>
                  {/* conditional rendering */}
                  {selectedRpc !== "" && (
                    <div className={Styles.buttonsContainer}>
                      <Button
                        type="button"
                        onClick={handleCopyValue}
                        theme="yellow"
                        cssButton={Styles.actionButton1}
                      >
                        <span>Copy URL</span>
                      </Button>

                      <Button
                        type="button"
                        onClick={handleAddToMetaMask}
                        theme="yellow"
                        cssButton={Styles.actionButton2}
                      >
                        <span>Add to MetaMask</span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    </section>
  );
};

export default PublicRPC;
