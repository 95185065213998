export const EVMChainsConfig = Object.freeze({
  Ethereum: {
    chainId: "0x1",
    chainName: "OMNIA Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/eth/mainnet/publicrpc"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  "Binance Smart Chain": {
    chainId: "0x38",
    chainName: "OMNIA Binance Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Binance",
      symbol: "BNB",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/bsc/mainnet/publicrpc"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  Polygon: {
    chainId: "0x89",
    chainName: "OMNIA Polygon Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Polygon",
      symbol: "POL",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mainnet/publicrpc"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  "Arbitrum One": {
    chainId: "0xa4b1",
    chainName: "OMNIA Arbitrum One",
    nativeCurrency: {
      decimals: 18,
      name: "Arbitrum",
      symbol: "ETH",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/arbitrum/one/publicrpc"],
    blockExplorerUrls: ["https://arbiscan.io"],
  },
  Avalanche: {
    chainId: "0xa86a",
    chainName: "OMNIA Avalanche Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Avalanche",
      symbol: "AVAX",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/avax/mainnet/publicrpc"],
    blockExplorerUrls: ["https://snowtrace.io"],
  },
  Aurora: {
    chainId: "0x4e454152",
    chainName: "OMNIA Aurora Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Aurora",
      symbol: "ETH",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/aurora/mainnet/publicrpc"],
    blockExplorerUrls: ["https://explorer.aurora.dev"],
  },
  Optimism: {
    chainId: "0xa",
    chainName: "OMNIA Optimism Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Optimism",
      symbol: "ETH",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/op/mainnet/publicrpc"],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
  },
  "Fantom Opera": {
    chainId: "0xfa",
    chainName: "OMNIA Fantom Opera",
    nativeCurrency: {
      decimals: 18,
      name: "Fantom",
      symbol: "FTM",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/fantom/mainnet/publicrpc"],
    blockExplorerUrls: ["https://ftmscan.com"],
  },
  "Harmony Shard 0": {
    chainId: "0x63564c40",
    chainName: "OMNIA Harmony Mainnet Shard 0",
    nativeCurrency: {
      decimals: 18,
      name: "Harmony",
      symbol: "ONE",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/harmony/mainnet/publicrpc"],
    blockExplorerUrls: ["https://explorer.harmony.one"],
  },
  Gnosis: {
    chainId: "0x64",
    chainName: "OMNIA Gnosis Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "Gnosis",
      symbol: "xDAI",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/gnosis/mainnet/publicrpc"],
    blockExplorerUrls: ["https://gnosisscan.io"],
  },
  Moonbeam: {
    chainId: "0x504",
    chainName: "OMNIA Moonbeam Mainnet #118",
    nativeCurrency: {
      decimals: 18,
      name: "Moonbeam",
      symbol: "GLMR",
    },
    rpcUrls: ["https://endpoints.omniatech.io/v1/moonbeam/mainnet/publicrpc"],
    blockExplorerUrls: ["https://moonbeam.moonscan.io"],
  },
});
