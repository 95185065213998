import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "../../components/Container/Container";
import * as Styles from "./Clients.module.scss";
import "swiper/css";
import Logo1 from "../../images/clients/Google.png";
import Logo2 from "../../images/clients/AWS.png";
import Logo3 from "../../images/clients/Microsoft.png";
import Logo4 from "../../images/clients/BloxRoute.png";
import Logo5 from "../../images/clients/LVTCapital.png";
import Logo6 from "../../images/clients/ChainGPT.png";
import Logo7 from "../../images/clients/Chainalysis.png";
import Logo8 from "../../images/clients/CloudFlare.png";
import Logo9 from "../../images/clients/LDCapital.png";
import Logo10 from "../../images/clients/CastrumCapital.png";


const logos = [
  { logo: Logo1 },
  { logo: Logo2 },
  { logo: Logo3 },
  { logo: Logo4 },
  { logo: Logo5 },
  { logo: Logo6 },
  { logo: Logo7 },
  { logo: Logo8 },
  { logo: Logo9 },
  { logo: Logo10 },
];

const Clients = () => {
  return (
    <section className={Styles.clientsWrap}>
      <div className={Styles.heading}>They trust us</div>
      <Container>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={16}
          slidesPerView={3.2}
          loop={true}
          centeredSlides={true}
          speed={3000}
          autoplay={{
            delay: null,
            disableOnInteraction: false,
          }}
          className={Styles.swiperAdditionalClass}
          breakpoints={{
            1024: {
              spaceBetween: 45,
              slidesPerView: 6,
              loopAdditionalSlides: 2,
            },
          }}
        >
          {logos.map((item, index) => {
            const { logo } = item;

            return (
              <SwiperSlide key={index}>
                <div className={Styles.slideWrap}>
                  <img src={logo} alt="" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </section>
  );
};

export default Clients;
