import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "../../components/Container/Container";
import * as Styles from "./Clients2.module.scss";
import "swiper/css";
import Logo1 from "../../images/clients2/BlockWallet.png";
import Logo2 from "../../images/clients2/CycurId.png";
import Logo3 from "../../images/clients2/GateIo.png";
import Logo4 from "../../images/clients2/Uniswap.png";
import Logo5 from "../../images/clients2/GMX.png";
import Logo6 from "../../images/clients2/Olympus.png";
import Logo7 from "../../images/clients2/WalletConnect.png";
import Logo8 from "../../images/clients2/SushiSwap.png";
import Logo9 from "../../images/clients2/WormHole.png";
import Logo10 from "../../images/clients2/FireBlocks.png";
import Logo11 from "../../images/clients2/Safe.png";
import Logo12 from "../../images/clients2/Multichain.png";


const logos = [
  { logo: Logo1 },
  { logo: Logo2 },
  { logo: Logo3 },
  { logo: Logo4 },
  { logo: Logo5},
  { logo: Logo6},
  { logo: Logo7},
  { logo: Logo8},
  { logo: Logo9},
  { logo: Logo10},
  { logo: Logo11},
  { logo: Logo12},
];

const Clients = () => {
  return (
    <section className={Styles.clientsWrap}>
      <div className={Styles.heading}>They Use OMNIA</div>
      <Container>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={16}
          slidesPerView={3.2}
          loop={true}
          centeredSlides={true}
          speed={3000}
          autoplay={{
            delay: null,
            disableOnInteraction: false,
          }}
          className={Styles.swiperAdditionalClass}
          breakpoints={{
            1024: {
              spaceBetween: 45,
              slidesPerView: 6,
              loopAdditionalSlides: 2,
            },
          }}
        >
          {logos.map((item, index) => {
            const { logo } = item;

            return (
              <SwiperSlide key={index}>
                <div className={Styles.slideWrap}>
                  <img src={logo} alt="" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </section>
  );
};

export default Clients;
