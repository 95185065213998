import React from "react";
import Container from "../../components/Container/Container";
import * as Styles from "./Featured.module.scss";
import "swiper/css";
import Logo1 from "../../images/articles/YahooFinance.png";
import Logo2 from "../../images/articles/CoinTelegraph.png";
import Logo3 from "../../images/articles/CryptoBriefing.png";
import Logo4 from "../../images/articles/Beincrypto.png";
import Logo5 from "../../images/articles/Benzinga.png";



const Featured = () => {
  return (
    <section className={Styles.ArticlesWrap}>
      <div className={Styles.heading}>FEATURED</div>
      <Container>
        <div className={Styles.ArticlesDiv}>
          <a
            className={Styles.imageWrap}
            href="https://finance.yahoo.com/news/critical-vulnerability-found-could-put-151227531.html?guccounter=1"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src={Logo1} alt="" />
          </a>
          <a
            className={Styles.imageWrap}
            href="https://cointelegraph.com/press-releases/omnia-protocol-helped-secure-the-privacy-of-21-million-users"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src={Logo2} alt="" />
          </a>
          <a
            className={Styles.imageWrap}
            href="https://cryptobriefing.com/omnia-protocol-helped-secure-the-privacy-of-21m-users/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src={Logo3} alt="" />
          </a>
          <a
            className={Styles.imageWrap}
            href="https://beincrypto.com/learn/what-is-omnia-protocol/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src={Logo4} alt="" />
          </a>
          <a
            className={Styles.imageWrap}
            href="https://www.benzinga.com/markets/cryptocurrency/22/01/25164842/cryptographer-ids-critical-metamask-privacy-vulnerability"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img src={Logo5} alt="" />
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Featured;
