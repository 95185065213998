import React from "react";
import Lottie from "reactjs-lottie";

const LottieAnimation = ({
  animationJson,
  loop = true,
  segment,
  direction = 1,
  autoplay = true,
  speed = 1,
  ...props
}) => {
  return (
    <Lottie
      speed={speed}
      options={{
        loop: loop,
        autoplay: autoplay,
        animationData: animationJson,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      isClickToPauseDisabled
      segments={segment}
      direction={direction}
      forceSegments={true}
      {...props}
    />
  );
};

export default LottieAnimation;
