import React from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "../../components/Container/Container";
import * as Styles from "./News.module.scss";
import NewsItems from "../../data/news.json";

const News = () => {
  return (
    <section className={Styles.newsWrap} id="blog">
      <div className={Styles.heading}>
        <Container smaller>LATEST NEWS</Container>
      </div>
      {NewsItems.slice(0, 4).map((item) => {
        const { title, url } = item;

        return (
          <div className={Styles.newsItem} key={uuidv4()}>
            <Container smaller>
              <a href={url} target="_blank" rel="noreferrer">
                <div className={Styles.title}>{title}</div>
                <div className={Styles.arrow}>
                  <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 1L32.5 16.5L16 31.5"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <line
                      x1="32"
                      y1="17"
                      x2="-8.74243e-08"
                      y2="17"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </a>
            </Container>
          </div>
        );
      })}
    </section>
  );
};

export default News;
