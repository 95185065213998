// extracted by mini-css-extract-plugin
export var actionButton = "Hero-module--actionButton--ee72b";
export var bottonsContainer = "Hero-module--bottonsContainer--f73cb";
export var contentWrap = "Hero-module--contentWrap--36e68";
export var description = "Hero-module--description--3de6f";
export var headingWrap = "Hero-module--headingWrap--00b86";
export var heroContainer = "Hero-module--heroContainer--cfe3d";
export var heroWrap = "Hero-module--heroWrap--2240f";
export var muteButtonWrap = "Hero-module--muteButtonWrap--24f47";
export var publicRpcButton = "Hero-module--publicRpcButton--1bd39";
export var sectionsContainer = "Hero-module--sectionsContainer--925a0";
export var title = "Hero-module--title--3df97";
export var typewriterWrap = "Hero-module--typewriterWrap--60db5";
export var videoWrap = "Hero-module--videoWrap--20386";