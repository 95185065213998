import React from "react";
import Container from "../Container/Container";
import * as Styles from "./SubscribeForm.module.scss";

const SubscribeForm = () => {
  return (
    <>
      <>
        <Container>
          <div className={Styles.subscribeHeadingBlock}>
            <div className={Styles.topSubHeading}>
              SUBSCRIBE TO OUR LATEST NEWS
            </div>
            <div className={Styles.topHeading}>
              Subscribe to ensure you get every update on Omnia's progress
            </div>
          </div>
        </Container>
      </>
      <div className={Styles.subscribeBottomRow}>
        <Container>
          <div className={Styles.subscribeFormBlock}>
            {/* <!-- Begin Mailchimp Signup Form --> */}
            <div id="mc_embed_signup">
              <form
                action="https://omniatech.us5.list-manage.com/subscribe/post?u=5344d21e1e46204e0c246a2cf&amp;id=38237adf9b"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                    <input
                      type="email"
                      // value=""
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      placeholder="Email Address"
                    />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_5344d21e1e46204e0c246a2cf_38237adf9b"
                      tabIndex="-1"
                      // value=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SubscribeForm;
