import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import LottieAnimation from "../../components/Lottie/Lottie";
import Container from "../../components/Container/Container";
import * as Styles from "./Roadmap.module.scss";
import "swiper/css";
import "swiper/css/navigation";

import Roadmap1 from "../../jsons/Roadmap1.json";
import Roadmap2 from "../../jsons/Roadmap2.json";
import Roadmap3 from "../../jsons/Roadmap3.json";
import Roadmap4 from "../../jsons/Roadmap4.json";
import Roadmap5 from "../../jsons/Roadmap5.json";

/**
 * January 1st - March 31st  = First Quarter
 * April 1st - June 30th = Second Quarter
 * July 1st - September 30th = Third Quarter
 * October 1st - December 31st = Fourth Quarter
 */

const roadmapList = [
  {
    json: <LottieAnimation animationJson={Roadmap4} />,
    time: "Q2 2021",
    title: "Pioneering Privacy",
    points: [
      "Exploring cutting-edge privacy solutions",
      "Uncovering the divide between on-chain and off-chain privacy",
    ],
    startTime: new Date("2021-04-01"),
    endTime: new Date("2021-06-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap5} />,
    time: "Q3 2021",
    title: "Innovation in Design",
    points: [
      "Researching market needs and use cases",
      "Conceptualizing the ultimate product",
      "Securing seed funding",
],
    startTime: new Date("2021-07-01"),
    endTime: new Date("2021-09-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap3} />,
    time: "Q4 2021",
    title: "Unleashing the MVP",
    points: [
      "Launching strategic and private sales",
      "Making HTTP endpoints available",
      "Providing node management through OMNIA",
    ],
    startTime: new Date("2021-10-01"),
    endTime: new Date("2021-12-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap2} />,
    time: "Q1 2022",
    title: "Early Adopter Access",
    points: [
      "Expanding support to more blockchain networks (Polygon & Avalanche)",
      "Offering DeFi frontrunning protection",
      "Enabling private transactions for DeFi",
    ],
    startTime: new Date("2022-01-01"),
    endTime: new Date("2022-03-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap1} />,
    time: "Q2 & Q3 2022",
    title: "HODL & Building for the Future",
    points: [
      "Introducing WebSockets support",
      "Enhancing stability with sticky sessions",
      "Expanding support to more blockchain networks (Arbitrum, Optimism)",
      "Dynamic subscription parameters validation",
    ],
    startTime: new Date("2022-04-01"),
    endTime: new Date("2022-09-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap4} />,
    time: "Q4 2022",
    title: " Introducing KYT Suite",
    points: [
      "Releasing OMNIA Smart Guard from KYT compliance suite",
      "Expanding support to more blockchain networks (Solana)",
      "Fine-tuning load balancer parameters for optimal performance",
    ],
    startTime: new Date("2022-10-01"),
    endTime: new Date("2022-12-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap5} />,
    time: "Q1 2023",
    title: "Strengthening Compliance",
    points: [
      "Releasing OMNIA Smart Spectator from KYT compliance suite",
      "Integrating various payment methods (card, stablecoins, etc.)",
      "Expanding support to more blockchain networks (Near, Aurora, Fantom, Gnosis)",
      "Adding connectors for external node providers",
      "Implementing CDN clustering",
    ],
    startTime: new Date("2023-01-01"),
    endTime: new Date("2023-03-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap3} />,
    time: "Q2 2023",
    title: "Test Token Launch",
    points: [
      "Launching testnet",
      "Releasing OMNIA Smart Insight from KYT compliance suite",
      "Enabling staking (testnet) for node providers",    
      "Expanding support to more blockchain networks (community requests)",
      "NFT Launch",
    ],
    startTime: new Date("2023-04-01"),
    endTime: new Date("2023-06-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap1} />,
    time: "Q3 2023",
    title: "Optimizing Performance",
    points: [
      "Introducing caching mechanism",
      "Enabling node verification protocol",
      "Expanding support to more blockchain networks (community requests)",
      "Capturing the good MEV and returning it back to users (PoC)",
    ],
    startTime: new Date("2023-07-01"),
    endTime: new Date("2023-09-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap3} />,
    time: "Q4 2023",
    title: "Leap Forward",
    points: [
      "Implementing request batching",
      "Expanding support to more blockchain networks (community requests)",
      "Enabling generic private transactions",
      "Offering system wide stateful subscriptions",
    ],
    startTime: new Date("2023-10-01"),
    endTime: new Date("2023-12-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap5} />,
    time: "Q1 & Q2 2024",
    title: "Achieving Omnipotence",
    points: [
      "Offering staking and rewards for node providers",
      "Providing use-case tailored node relaying (i.e. speed tailored, etc)",
      "Expanding support to more blockchain networks (community requests)",
      "Introducing multi-chain private mempools",
      "RPC aggregator and balancer",
      "DeFi tools to boost DEX volumes"
    ],
    startTime: new Date("2024-01-01"),
    endTime: new Date("2024-06-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap4} />,
    time: "Q3 2024",
    title: "Double down on security",
    points: [
      "2FA for transactions",
      "Customizable security policies",
      "Integrated TX flow security",
      "Provider services aggregation via plugins",
      "Taping the restaking & AVS operator lanscape",
      "Releasing OMNIA Tap Game"
    ],
    startTime: new Date("2024-07-01"),
    endTime: new Date("2024-09-30"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap3} />,
    time: "Q4 2024",
    title: "Mainnet & Token listing",
    points: [
      "Token launch and exchange listing",
      "Liquidity incentives programs",
      "Node dePIN staking",
      "Data integrity checks via consensus",
      "Tapping into L2 ZKP ecosystem",
      "Multiblock MEV leveraging AI models and big data"
    ],
    startTime: new Date("2024-10-01"),
    endTime: new Date("2024-12-31"),
  },
  {
    json: <LottieAnimation animationJson={Roadmap2} />,
    time: "2025 ",
    title: "Capturing Market & Revenue",
    points: [
      "Convert userbase in paying customers",
      "AI natural language assistant to understand onchain exposure",
      "Launch of OMNIA AVS",
      "Secure the decentralized data integrity checks with restaking"
    ],
    startTime: new Date("2025-01-01"),
    endTime: new Date("2025-12-31"),
  }
];

const Roadmap = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section className={Styles.roadmapWrap} id="roadmap">
      <Container>
        <div className={Styles.sliderWrap}>
          <Container smaller>
            <div className={Styles.topRow}>
              <div>
                <div className={Styles.sliderWrapSubHeading}>ROADMAP</div>
                <div className={Styles.sliderWrapHeading}>Product and token roadmap</div>
              </div>
              <div className={Styles.rightSide}>
                <button ref={navigationPrevRef} className={"prev"}>
                  <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 1L1.5 16.5L18 31.5"
                      stroke="#18181A"
                      strokeWidth="2"
                    />
                    <line
                      y1="-1"
                      x2="32"
                      y2="-1"
                      transform="matrix(1 -8.74243e-08 -8.74243e-08 -1 2 16)"
                      stroke="#18181A"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
                <button ref={navigationNextRef} className={"next"}>
                  <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 1L32.5 16.5L16 31.5"
                      stroke="#18181A"
                      strokeWidth="2"
                    />
                    <line
                      x1="32"
                      y1="17"
                      x2="-8.74243e-08"
                      y2="17"
                      stroke="#18181A"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </Container>
          <div className={Styles.sliderWrapper}>
            <Swiper
              initialSlide={12}
              modules={[Navigation]}
              spaceBetween={261}
              slidesPerView={1}
              slidesPerGroup={1}
              grabCursor={true}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
                clickable: true,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              breakpoints={{
                1024: {
                  slidesPerGroup: 2,
                  slidesPerView: 2,
                },
              }}
              className={Styles.primarySwiper}
            >
              {roadmapList.map((item) => {
                const { json, time, title, points, startTime, endTime } = item;
                return (
                  <SwiperSlide key={uuidv4()}>
                    <div
                      className={cn(Styles.slideWrap, {
                        [`${Styles.slideActive}`]: (
                            (new Date() > startTime) && (new Date() < endTime)
                        ),
                      })}
                    >
                      <div className={Styles.lottieAnimation}>{json}</div>
                      <div className={Styles.time}>{time}</div>
                      <div className={Styles.slideTitle}>{title}</div>
                      <ul>
                        <li>{title}</li>
                        {points.map((item) => {
                          return <li key={uuidv4()}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Roadmap;
