// extracted by mini-css-extract-plugin
export var containerStyle = "Slides-module--containerStyle--831f5";
export var description = "Slides-module--description--7863b";
export var itemSlideContent = "Slides-module--itemSlideContent--b2a8f";
export var lottieContainer = "Slides-module--lottieContainer--8b22e";
export var lottieContainerMobile = "Slides-module--lottieContainerMobile--9140a";
export var lottiePlaceholder = "Slides-module--lottiePlaceholder--70c03";
export var mobileSectionWrap = "Slides-module--mobileSectionWrap--dd172";
export var sectionWrap = "Slides-module--sectionWrap--e6af9";
export var subtitle = "Slides-module--subtitle--da22b";
export var swiper = "Slides-module--swiper--276f0";
export var swiperMobile = "Slides-module--swiperMobile--88605";
export var title = "Slides-module--title--67a04";