import React, { useState } from "react";
import Container from "../../components/Container/Container";
import * as Styles from "./TeamFull.module.scss";
import TeamMembers from "../../data/team.json";
import { SvgLinkedin } from "../../components/SVG";
import Button from "../../components/Button/Button";

const INIT_MEMBERS_LENGTH = 6;
const INIT_MEMBERS = TeamMembers.slice(0, INIT_MEMBERS_LENGTH);
const LOAD_MEMBER_LENGTH = 3;

const TeamFull = () => {
  const [members, setMembers] = useState(INIT_MEMBERS);
  const canLoadMore = TeamMembers.length > members.length;

  const loadMore = () => {
    setMembers((prevState) =>
      TeamMembers.slice(0, prevState.length + LOAD_MEMBER_LENGTH)
    );
  };

  return (
    <section className={Styles.teamWrap}>
      <Container smaller>
        <div className={Styles.headingWrap}>
          <div className={Styles.subHeading}>Team</div>
          <div className={Styles.heading}>Meet the team</div>
        </div>
        <div className={Styles.row}>
          {members.map((item, index) => {
            const { name, position, linkedin, photo } = item;

            return (
              <React.Fragment key={index}>
                {!linkedin && (
                  <div className={Styles.teamMember}>
                    <div className={Styles.imgWrap}>
                      <img src={photo} alt={name} />
                    </div>
                    <div className={Styles.name}>{name}</div>
                    <div className={Styles.bottomRow}>
                      <div className={Styles.position}>{position}</div>
                    </div>
                  </div>
                )}
                {linkedin && (
                  <a
                    href={linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className={Styles.teamMember}
                  >
                    <div className={Styles.imgWrap}>
                      <img src={photo} alt={name} />
                    </div>
                    <div className={Styles.name}>{name}</div>
                    <div className={Styles.bottomRow}>
                      <div className={Styles.position}>{position}</div>
                      <div>
                        <div className={Styles.divider}></div>
                        <SvgLinkedin width="16" />
                      </div>
                    </div>
                  </a>
                )}
              </React.Fragment>
            );
          })}
        </div>

        {canLoadMore && (
          <div className={Styles.loadmoreBtn}>
            <Button type="button" onClick={() => loadMore()}>
              Load more
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default TeamFull;
