// extracted by mini-css-extract-plugin
export var bottomRow = "TeamFull-module--bottomRow--4ddf5";
export var divider = "TeamFull-module--divider--d682b";
export var heading = "TeamFull-module--heading--16fd9";
export var headingWrap = "TeamFull-module--headingWrap--667f2";
export var imgWrap = "TeamFull-module--imgWrap--1803a";
export var loadmoreBtn = "TeamFull-module--loadmoreBtn--003f0";
export var name = "TeamFull-module--name--39c98";
export var position = "TeamFull-module--position--407cd";
export var row = "TeamFull-module--row--7728e";
export var subHeading = "TeamFull-module--subHeading--838ea";
export var teamMember = "TeamFull-module--teamMember--31ea7";
export var teamWrap = "TeamFull-module--teamWrap--8cc8f";